import styled, { createGlobalStyle, css } from 'styled-components'
import { white, grey, ligthGrey } from 'styles/colors'

/**
 * @constant
 */
const SIZE = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px'
}

/**
 * @param {{
 * sm: string,
 * md: string,
 * lg: string,
 * xl: string
 * }} size
 */
export const device = Object.keys(SIZE).reduce((acc, key) => {
  acc[key] = style => `
    @media (min-width: ${SIZE[key]}) {
      ${style}
    }
  `
  return acc
}, {})

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    height: 100%;
  }
  #app {
    height: 100%;
  }
`

export const FlexCenterWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FlexBetweenWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FlexEvenlyWrapper = css`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const FlexCenterColumns = css`
  display: flex;
  flex-direction: column;
`

export const AppBody = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 3.5rem calc(100% - 7.5rem) 4rem;
  grid-template-columns: auto;
  grid-template-areas:
    'topbar'
    'app'
    'menu';
  ${device.md`
    grid-template-rows: auto;
    grid-template-columns: 15rem calc(100% - 15rem);
    grid-template-areas: 'menu app';
  `}
`

export const AppWrapper = styled.div`
  height: 100%;
  grid-area: app;
  background-color: ${white};
`

export const Title = styled.h1`
  color: ${grey};
  font-size: 23px;
  margin: 0;
  letter-spacing: 1.5px;
  text-align: center;
  ${device.md`
    font-size: 33px;
  `}
`

export const SubTitle = styled.h1`
  color: ${grey};
  font-size: 20px;
  margin: 0;
  letter-spacing: 1.5px;
  text-align: center;
  ${device.md`
    font-size: 23px;
  `}
`

export const Legend = styled.h1`
  color: ${({ color }) => color || ligthGrey};
  font-size: 10px;
  margin: 0;
  letter-spacing: 1.5px;
  text-align: center;
  ${device['md']`
    font-size: 13px;
  `}
`

export const SVGWrapper = styled.svg`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  padding: ${({ padding }) => padding};
`
