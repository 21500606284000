// Libraries
import store from 'store'
import { createRoot } from 'react-dom/client'
import React, { lazy, Suspense } from 'react'
import * as Sentry from '@sentry/browser'
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom'

// Components
import Loading from 'components/loading'
import { GlobalStyle } from './styles/index'

const App = lazy(() => import('./app'))
const Login = lazy(() => import('./login'))

const sentryDsn = process.env.REACT_APP_SENTRY_DSN
const onProduction =
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '0.0.0.0' &&
  process.env.NODE_ENV === 'sandbox'


const PrivateRoute = ({ redirectTo = "/login" }) => {
  const hasToken = store.get('AUTH_TOKEN')
  if (!hasToken) {
    store.clearAll()
    return <Navigate to={redirectTo} />
  }

  return <Outlet />
}

const Router = () => (
  <React.Fragment>
    <GlobalStyle />
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Navigate to={'llamar'} />} />
          <Route element={<PrivateRoute />}>
            <Route path='*' element={<App />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  </React.Fragment>
)

if (onProduction) {
  Sentry.init({
    dsn: sentryDsn
  })
}
const rootElement = document.getElementById('app')
const root = createRoot(rootElement)

root.render(<Router />)
