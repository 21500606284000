// Libraries
import React from 'react'
import styled from 'styled-components'

// Components
import { white } from 'styles/colors'
import { ReactComponent as Spinner } from 'assets/spinner.svg'

const SpinnerWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 1em;
  & svg {
    fill: ${({ color }) => color};
    width: ${({ width }) => width};
  }
`
/**
 * @typedef {{
 * color?: string,
 * width?: string
 * }} LoadingProps
 * @param {LoadingProps} props
 */
const Loading = ({ color = white, width = '2em' }) => {
  return (
    <SpinnerWrapper color={color} width={width}>
      <Spinner title='loading' />
    </SpinnerWrapper>
  )
}

export default Loading
