export const white = '#fff'
export const primaryBlue = '#145DA5'
export const primaryGreen = '#77BC1F'
export const primaryRed = '#E82615'
export const darkGrey = '#474747'
export const grey = '#9B9FA4'
export const blueGrey = '#5B6B8B'
export const secondaryBlueGrey = '#D5E9FF'
export const ligthGrey = '#EDF4FD'
export const whiteGrey = '#F4F9FF'
export const ligthBlue = '#4482D0'
export const blue = '#092548'
export const darkBlue = '#13396A'
export const placeholderGrey = '#7F7F9D'
export const labelGrey = '#5F5F79'
export const theadGrey = '#D0D0E6'